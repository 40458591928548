<template>
   <div class="entry-copyright">
        <p>&copy; {{ year }} <a :href="siteLink">{{ siteName }}</a>. All Rights Reserved. <a :href="privacyLink">Privacy Policy</a></p>
    </div>
</template>
<script>
    export default {
        props: {
            year: {
                type: String,
                default: ""
            },
            siteLink: {
                type: String,
                default: ""
            },
            siteName: {
                type: String,
                default: ""
            },
            privacyLink: {
                type: String,
                default: ""
            },
        }
    }
</script> 