<template>
   <!-- <div id="social-profiles">
      <nav class="social-menu">
         <ul>
            <li class="menu-item"><a :href="facebook_link" target="_blank"><span class="mdi"></span><span
                     class="screen-reader-text">Facebook Page</span></a></li>
            <li class="menu-item"><a :href="twitter_link" target="_blank"><span class="mdi"></span><span
                     class="screen-reader-text">Twitter Profile</span></a></li>
            <li class="menu-item"><a :href="instagram_link" target="_blank"><span class="mdi"></span><span
                     class="screen-reader-text">Instagram Profile</span></a></li>
         </ul>
      </nav>
   </div> -->
</template>
<script>
export default {
   props: {
      facebook_link: {
         type: String,
         default: ""
      },
      twitter_link: {
         type: String,
         default: ""
      },
      instagram_link: {
         type: String,
         default: ""
      },
   }
}
</script>