<template>
    <HeaderSection v-bind="headerProps" />
    <SocialBox v-bind="socialProps" />
    <section class="hero-media">
		<Carousel :items="mediaInfo" />
	</section>    

    <div class="content-wrap">
		<div class="entry entry-page">
			<h2 class="entry-title">{{content.title}}</h2>
			<div class="entry-content">
				<p>{{content.desc}}</p>
				<form class="searchform" role="search">
					<label class="screen-reader-text" for="s">Search for:</label>
					<input v-model="searchVal" name="s" id="s" placeholder="Search&hellip;" type="search">
					<button @click="$emit('search', searchVal)"><span class="screen-reader-text">Search</span><span class="mdi mdi-magnify"></span></button>
				</form>
				<p>Or perhaps take a look at our most visited pages:</p>
				<ul>
					<li v-for="(item, index) in content.links" :key="index"><a :href="item.url">{{item.label}}</a></li>
				</ul>
			</div>
		</div>
		<CopyRight v-bind="footerProps"/>
	</div>
</template>

<script>
import HeaderSection from '../../organisms/Header/Header.vue';
import SocialBox from "../../molecules/SocialBox/SocialBox.vue";
import SingleBlog from '../../organisms/SingleBlog/SingleBlog.vue';
import Carousel from '../../organisms/Carousel/Carousel.vue';
import CopyRight from '../../molecules/CopyRight/CopyRight.vue';
import { footerProps } from '../../constants';
/**
 * - Use it to show a simple notification
 */
export default {
    components: {
        HeaderSection, SocialBox, SingleBlog, Carousel, CopyRight
    },
    props: {
        /**
         * Use it for notification title
         */
        headerProps: {
            type: Object,
            default: () => null
        },
        socialProps: {
            type: Object,
            default: () => null
        },
        mediaInfo: {
            type: Array,
            default: () => []
        },
        content: {
            type: Object,
            default: () => null
        }
    },
    data() {
        return {
            searchVal: ""    ,
            footerProps
        }
    },
}
</script>

<style>

</style>