<template>
  <PageNotFoundTemplate
    :headerProps="headerProps"
    :socialProps="socialProps"
    :mediaInfo="mediaInfo"
    :content="content"
  />
</template>

<script>
import PageNotFoundTemplate from "@/components/templates/404Page/404Page";
import { headerProps, socialProps } from "@/components/constants.js";
import heroImg from "@/assets/images/sample-hero.jpg";
export default {
  components: {
    PageNotFoundTemplate,
  },
  data() {
    return {
      headerProps: { ...headerProps, currentRoute: this.$route.name },
      socialProps: socialProps,
      mediaInfo: [
        {
          image: heroImg,
        },
      ],
      content: {
        title: "Oops, it happens",
        desc: "Apologies, but no results were found for the requested page. Maybe try a search?",
        links: [
          {
            label: "Booking page",
            url: "",
          },
          {
            label: "Accomodation room",
            url: "",
          },
          {
            label: "Latest blog entries",
            url: "",
          },
          {
            label: "Dinning menu",
            url: "",
          },
          {
            label: "Send us a message",
            url: "",
          },
        ],
      },
    };
  },
};
</script>

<style scoped></style>
