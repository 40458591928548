<template>
    <div class="content-wrap">
        <div class="entry entry-page">
            <h2 class="entry-title mb-11" v-html="blog.title"></h2>
            <div class="entry-content">
                <article class="entry-post">
                    <header class="entry-header">
                        <div class="meta-data">
                            <span class="meta-date"><em class="mdi mdi-calendar-clock"></em><a href="single-post.html">{{blog.postedAt}}</a></span>
                            <span class="meta-categories"><em class="mdi mdi-folder-outline"></em><a href="blog.html">Blog</a></span>
                        </div>
                    </header>
                    <div class="entry-content" v-html="blog.content"></div>

                    <aside class="author-box has-description clearfix">
                        <figure class="author-box-image">
                            <img alt="" :src="blog.author.img" class="avatar photo" width="150" height="150">
                        </figure>
                        <div class="author-content">
                            <h5 class="author-box-title text-2xl">Published by <a :href="blog.author.link" title="Visit Liviu Cerchez’s website" rel="author external">{{blog.author.name}}</a></h5>
                            <div class="author-box-description" v-html="blog.author.desc"></div>
                        </div>
                    </aside>

                    <div id="comments">
                        <div id="respond" class="comment-respond">
                            <h5 id="reply-title" class="comment-reply-title text-2xl">Leave a Reply</h5>
                            <form @submit="$emit('post-comment', comment)" class="comment-form">
                                <p class="logged-in-as">
                                    Your email address will not be published.
                                </p>
                                <p class="comment-form-author">
                                    <label for="email">Name</label>
                                    <input id="author" name="author" v-model="comment.name" size="35" type="text">
                                </p>
                                <p class="comment-form-email">
                                    <label for="email">Email</label>
                                    <input id="email" name="email" v-model="comment.email" size="35" type="text">
                                </p>
                                <p class="comment-form-comment mb-4">
                                    <label for="comment">Comment</label>
                                    <textarea id="comment" name="comment" cols="45" rows="8" v-model="comment.content"></textarea>
                                </p>
                                <p class="form-submit">
                                    <input name="submit" id="submit" class="submit button-color button-filled" value="Post Comment" type="submit">
                                </p>
                            </form>
                        </div>
                    </div>
                </article>
            </div>
        </div>
        <CopyRight v-bind="footerProps"/>
    </div>
</template>

<script>
/**
 * 
 */
 import CopyRight from '../../molecules/CopyRight/CopyRight.vue';
import { footerProps } from '../../constants';
export default {
    components: {
        CopyRight
    },
    props: {
        blog: {
            type: Object,
            default: () => null
        }
    },
    data() {
        return {
            footerProps,
            comment: {
                name: "",
                email: "",
                content: ""
            }
        }
    }
}
</script>

<style>
    .entry-content p {
        margin-bottom: 1.625em;
    }

    .entry-content blockquote {
        margin: 1.625em 0 3.25rem 0;
    }

    .entry-content h5 {
        margin-bottom: 1.625rem;
    }
</style>